import React from "react";
import styled from "styled-components";
import IconButton from "./IconButton";
import Counter from "./Counter";
import { useInSessionWaitingRoomContext } from "../../InSessionWaitingRoom";

import icon from "../../../assets/waiting-room-icon.svg";
import useCopy from "../../../hooks/useCopy";

const Icon = styled.img`
  width: 50%;
`;

export default function WaitingRoomToggleButton({
  waitingRoomOpen,
  setWaitingRoomOpen,
}) {
  const { notification } = useInSessionWaitingRoomContext();
  const [showWaitingRoomText] = useCopy(["sessioncontrols.showWaitingRoom"]);

  const onClick = e => {
    e.stopPropagation();
    setWaitingRoomOpen(!waitingRoomOpen);
  };

  return (
    <IconButton onClick={onClick} id="toggleWaitingRoom">
      <Icon src={icon} alt={showWaitingRoomText} />
      {notification ? <Counter id="waitingRoomCount" /> : null}
    </IconButton>
  );
}
